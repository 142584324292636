import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout';
import SocialRef from '../components/SocialRef';
import FromTheNews from '../components/FromTheNews';
import './single-film.scss';

const FilmCoconShell = () => (
  <Layout className="single-film">
    <section className="film-hero">
      <Container>
          <Row>
            <Col xs="12" md="5" className="mb-4">
              <div className="text-center">
                <Fade>
                <img loading="lazy" src="/files/poster-film-the-yellow-cocoon-shell.jpg" alt="INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)"/>
                </Fade>
              </div>
            </Col>
            <Col xs="12" md="7">
              <h1 className="film-title">
              INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)
              </h1>
              <div className="film-desc">
                <p>
                 After his sister-in-law dies in a freak motorcycle accident in Saigon, Thien is bestowed the task of delivering her body in their countryside hometown to which he also takes his nephew Dao (5) who miraculously survived the crash.
                </p>
                <p>
                Amidst the mystical landscapes of rural Vietnam, Thien begins a search for his older brother who vanished years ago to hand Dao over to him - a journey which deeply questions his faith.
                </p>
              </div>
              <div className="film-specs">
                <div>
                  <strong>Genres:</strong> Drama | 2h 57m
                </div>
                <div>Vietnam | Singapore | France</div>
                <div>
                  <strong>Language:</strong> Vietnamese
                </div>
                <div>
                  <strong>Release Date:</strong> 17 May 2023 (France)
                </div>
                <div>
                  <strong>Director:</strong> Pham Thien An
                </div>
                <div>
                  <strong>Writer:</strong> Pham Thien An
                </div>
                <div>
                  <strong>Producer:</strong> Tran Van Thi, Jeremy Chua
                </div>
                <div>
                  <strong>Key Cast:</strong> Le Phong Vu, Nguyen Thinh, Nguyen Thi Truc Quynh, Vu Ngoc Manh
                </div>
              </div>
              <SocialRef
                facebook="https://www.facebook.com/Insidetheyellowcocoonshell"
                imdb="https://www.imdb.com/title/tt10399622/"
                vimeo=""
              />
            </Col>
          </Row>
      </Container>
    </section>
    <Container>
      <Row>
        <Col md="8">
          {/* <div className="video-responsive">
            <iframe src="https://player.vimeo.com/video/331872264" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
            <p><a href="https://vimeo.com/331872264">H&atilde;y Tỉnh Thức V&agrave; Sẵn S&agrave;ng | Stay Awake, Be Ready | Trailer - Pham Thien An</a> from <a href="https://vimeo.com/phamthienan">Pham Thien An</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
          </div> */}
          <img className="mb-4" src="/files/canh-trong-phim-inside-the-yellow-cocoon-shell-1.png" alt="INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)"/>
          <img className="mb-4" src="/files/canh-trong-phim-inside-the-yellow-cocoon-shell-2.png" alt="INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)"/>
          <img className="mb-4" src="/files/canh-trong-phim-inside-the-yellow-cocoon-shell-3.png" alt="INSIDE THE YELLOW COCOON SHELL – BÊN TRONG VỎ KÉN VÀNG (2023)"/>
          <FromTheNews />
        </Col>
        <Col md="4">
          <div className="widget" hidden>
            <div className="widget-title">
              Director's statement
            </div>
            <div className="widget-content">
            Street pubs & stalls are places where people in Vietnam can chat many things without fear of being judged. Through the conversation of three young men before the street stalls on a street corner, a traffic accident happened suddenly nearby, the director wanted to sketch a multi-color picture of the humanity. When society makes people rush day by day, they often think of themselves as the center of the universe and actually forget how small people really are in the big world.
            </div>
          </div>
          <div className="widget" hidden>
            <div className="widget-title">
            Distributor
            </div>
            <div className="widget-content">
              <div>
              Lights On Film
              </div>
              <a rel="noopener noreferrer" aria-label="Lights On Film" href="https://www.lightsonfilm.com/" target="_blank">www.lightsonfilm.com</a>
            </div>
          </div>

          <div className="widget">
            <div className="widget-content">
              <a href="https://mubi.com/en/films/inside-the-yellow-cocoon-shell/awards" target="_blank">AWARDS & FESTIVALS</a>
              <br /><br /><a href="https://editorial.rottentomatoes.com/guide/best-new-movies/" target="_blank">BEST MOVIES OF 2024: BEST NEW MOVIES TO WATCH NOW</a>
              <br /><br /><a href="https://www.facebook.com/letterboxd/posts/987835316675537" target="_blank">The 25 Best Movies of 2024 (So Far), according to Paste Magazine</a>
              <br /><br /><a href="https://www.bfi.org.uk/sight-and-sound/polls/50-best-films-2023" target="_blank">The 50 best films of 2023</a>
            </div>
          </div>
          <div className="widget" hidden>
            <div className="widget-content">
              14min | Vietnam, South Korea, USA | 2019  <br />

              Year of production 2019 <br />
              Length 14 min <br />
              Country Vietnam, South Korea, USA <br />
              Shooting Format ARRI Alexa <br />
              Aspect Ratio 1:1,85 <br />
              Sound 5.1 <br />
              Dialogue Vietnamese

            </div>
          </div>
          <div className="widget" hidden>
            <div className="widget-content">
              <strong>Director</strong> Pham Thien An <br/>
              <strong>Producers</strong> Pham Thien An, Tran Van Thi <br/>
              <strong>Production</strong> JKFILM <br/>
              <strong>Writer</strong> Pham Thien An <br/>
              <strong>Cinematographer</strong> Dinh Duy Hung <br/>
              <strong>Production Designer</strong> Huynh Phuong Hien, Nguyen Minh Quyen <br/>
              <strong>Production Coordinator</strong> Pham Thi Phuong Thao, Nguyen Lam Quynh Han <br/>
              <strong>Production Assistant</strong> Diep Phuong Uyen, Pham Thien Phuc<br/>
              <strong>Editor</strong> Pham Thien An <br/>
              <strong>Director Assistant</strong> Pham Tuan Vu<br/>
              <strong>Camera Assistant</strong> Do Tien Dat, Nguyen Tri Vuong Ba <br/>
              <strong>Sound Mixing</strong> Tamas Steger<br/>
              <strong>Sound Recording</strong> Do Vu<br/>
              <strong>BTS Still</strong> Luu Binh An <br/>
              <strong>Key Cast</strong> Le Quoc Bao Vi, Vu Trong Tuyen, Trung Dong Cao, Be Hieu, Huynh Ngoc Hanh
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default FilmCoconShell;
